// ** Third Party Components
import styled from "styled-components";

// #####################################################

export const StyledOuterContainer = styled("div")`
	width: 100%;
	margin-right: auto;
	margin-left: auto;

	margin-top: 0;
	position: relative;
	z-index: 2;
	background-color: var(--dz-sys-card-background-color);
`;

// #####################################################
