// ** Redux & Store & Actions
import { useSelector } from "react-redux";

// ** Third Party Components
import { useTranslation } from "react-i18next";
import loadable from "@loadable/component";

// ** Custom Components
import Text from "components/typography/Text";
import Block from "components/layout/Block";
import Link from "components/layout/Link";
import SimpleMenu from "components/layout/SimpleMenu";
const LangSelector = loadable(() => import("components/layout/LangSelector"), {
	ssr: false,
});
import Toggle from "components/layout/Toggle/Toggle";
import ServicePageNavMenu from "components/global/ServicePageNavMenu";

// ** Utils & Helpers
import formatMessage from "utils/formatMessage";

// ** Custom Hooks
import useDarkMode from "hooks/useDarkMode";

// ** Styled Components
import {
	StyledOuterContainer,
	StyledInnerContainer,
	StyledFooterLogo,
} from "./styles";

// #####################################################

const currentDate = new Date().getFullYear();

// #####################################################

const Footer = () => {
	const { t } = useTranslation(["common", "landing", "pages"]);

	const isClient = useSelector((state) => state.global.isClient);

	const isDarkMode = useSelector((state) => state.global.darkMode.darkMode);

	const { toggleTheme } = useDarkMode();

	// #####################################################

	return (
		<StyledOuterContainer as="footer">
			<StyledInnerContainer>
				<Block flex>
					<Block flex column full justifyEnd mr={1}>
						{/* Przełącznik Trybu Ciemny / Jasny */}
						<Block flex mb={2} id="variantDark">
							<Text color="grey" size="0.9rem" mr={2}>
								{t("common:darkMode")}:{" "}
							</Text>
							{isClient && (
								<Toggle
									onToggle={() => toggleTheme()}
									toggled={isDarkMode}
								/>
							)}
						</Block>
						{isClient && (
							<SimpleMenu
								size="0.9rem"
								title={t("common:language")}
							>
								<SimpleMenu.List>
									<SimpleMenu.Item>
										<LangSelector
											parentIsFooter={true}
											label={t("common:language")}
										/>
									</SimpleMenu.Item>
								</SimpleMenu.List>
							</SimpleMenu>
						)}
						<Block mt={3} flex>
							<Text color="grey" size="0.9rem" mr={2}>
								{formatMessage(t(`landing:cookie.info`), {
									privacePolicy: (
										<Link
											underline
											to="/privacy"
											key="landing:cookie.link"
										>
											{t(`landing:cookie.link`)}
										</Link>
									),
								})}
							</Text>
						</Block>
						<Block mt={2}>
							<Text color="grey" size="0.9rem" mr={2}>
								{t("common:service")}:{" "}
							</Text>
							<ServicePageNavMenu />
						</Block>
					</Block>
					<Block textRight alignEnd ml={1}>
						<a href="https://www.datezone.com">
							<StyledFooterLogo
								mode={isDarkMode ? "white" : "blue"}
							/>
						</a>
						<Text small>{currentDate} Datezone</Text>
					</Block>
				</Block>
				<div style={{ height: "58px" }}></div>
			</StyledInnerContainer>
		</StyledOuterContainer>
	);
};

// #####################################################

export default Footer;

// #####################################################
